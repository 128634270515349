@import '~antd/dist/antd.css';

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: Roboto;

  src: url('./assets/fonts/roboto-regular.woff2') format('woff2'),
    url('./assets/fonts/roboto-regular.woff') format('woff');
  text-rendering: optimizeSpeed;
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: Roboto;

  src: url('./assets/fonts/roboto-500.woff2') format('woff2'),
    url('./assets/fonts/roboto-500.woff') format('woff');
  text-rendering: optimizeSpeed;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: Roboto;

  src: url('./assets/fonts/roboto-700.woff2') format('woff2'),
    url('./assets/fonts/roboto-700.woff') format('woff');
  text-rendering: optimizeSpeed;
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: Barlow;

  src: local(''), url('./assets/fonts/barlow-500.woff2') format('woff2'),
    url('./assets/fonts/barlow-500.woff') format('woff');
  text-rendering: optimizeSpeed;
}

@font-face {
  font-style: normal;
  font-weight: normal;
  font-family: Nunito;

  src: url('./assets/fonts/nunito-sans-regular.woff2') format('woff2'),
    url('./assets/fonts/nunito-sans-regular.woff') format('woff');
  text-rendering: optimizeSpeed;
}

@font-face {
  font-style: normal;
  font-weight: bold;
  font-family: Nunito;

  src: url('./assets/fonts/nunito-sans-700.woff2') format('woff2'),
    url('./assets/fonts/nunito-sans-700.woff') format('woff');
  text-rendering: optimizeSpeed;
}

html * {
  font-family: Roboto, sans-serif;
}

#root {
  overflow: hidden;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}
body {
  overflow: hidden;
}

#root {
  position: relative;
}

/* notification */
.ant-notification-notice-close {
  position: absolute;
  top: unset !important;
  right: 1.25rem;
}
.ant-notification-notice-close .ant-notification-close-x {
  display: flex;
}
.ant-notification-notice-with-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ant-notification-notice-message,
.ant-notification-notice-description {
  margin-left: 0 !important;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 0;
  font-size: unset;
  line-height: unset;
}
.ant-notification-notice-icon {
  left: 1rem;
}
